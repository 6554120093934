<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack('my-product')"> สินค้าของฉัน </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ navigete }}
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- เลือกสินค้า -->
    <v-dialog v-model="addStockPopup.dialog" scrollable :max-width="addStockPopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span><h5> ค้นหา : </h5></span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field
                ref="refSearchStockModelPopup"
                :value="searchStockModelPopup"
                autocomplete="off"
                class="pl-2"
                dense
                hide-details
                @change="value => searchStockModelPopup = Trim_value(value)"
                @keyup.enter="PopupsearchStock(limits,0)"
              ></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn color="primary" class="mr-1 px-2" @click="PopupsearchStock(limits,0)">
                <v-icon center >mdi-magnify</v-icon>
                  ค้นหา
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="addStockPopup.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addStockPopup.MaxHeight+'px'}">
          <v-data-table
            :headers="headers_selectProductList"
            :items="addStockPopup.dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:item.selectProduct="{ item }">
            <v-btn v-if="item.selectProduct" x-small depressed color="primary" @click="ProductSelectCheckbox(item,addStockPopup.dataset.indexOf(item))"> เลือก </v-btn>
            <v-btn v-else outlined x-small depressed color="primary" @click="ProductSelectCheckbox(item,addStockPopup.dataset.indexOf(item))"> เลือก </v-btn>
          </template>
          <template v-slot:item.no="{ item }">
            {{ (addStockPopup.dataset.indexOf(item)+1)+offsets }}
          </template>
          <template v-slot:item.photoLink="{ item }">
            <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" class="picsize mt-1">
            </a>
          </template>
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.prop1="{ item }">
            <span class="fn-13">
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
          </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

        <!-- Mobile -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-sm-and-up" :style="{height: addStockPopup.MaxHeight+'px'}">
          <v-data-iterator
            :items="addStockPopup.dataset"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content">
                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ชื่อสินค้า
                              </div>
                              <div style="line-height: 1rem !important;">
                                : {{ item.name }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  ลักษณะ
                                </div>
                                <div>
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  SKU
                                </div>
                                <div>
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  Barcode
                                </div>
                                <div>
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  รหัสสินค้า
                                </div>
                                <div>
                                  <v-row>
                                    <v-col cols="6" class="pa-0">
                                      : {{ item.productCode }}
                                    </v-col>
                                    <v-col cols="6" class="pa-0"/>
                                  </v-row>
                                </div>
                                <v-btn v-if="item.selectProduct" x-small depressed color="primary" @click="ProductSelect(item,addStockPopup.dataset.indexOf(item))"> เลือก </v-btn>
                                <v-btn v-else outlined x-small depressed color="primary" @click="ProductSelect(item,addStockPopup.dataset.indexOf(item))"> เลือก </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card-title>

                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <!-- Mobile -->

        <div v-if="pageLength>1 && addStockPopup.dataset.length>0" class="text-center pa-1 ma-0" @click="changePage()">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addStockPopup.loading" @click="Submit_addStockPopup()">
              เพิ่มสินค้า
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- from -->
    <v-form ref="addstockForm" lazy-validation>
      <!-- ข้อมูลทั่วไป -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="teal darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">ข้อมูลการขาย</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> ประเภทสินค้า : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-radio-group row v-model="productMaster.isProductSetTemp" hide-details>
                <v-radio v-for="i in productMaster.isProductSetList" color="info" :disabled="ChackEditRdo(productMaster.id)" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> ภาพสินค้า : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <a id="pick-avatar">
                <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                  <img :src="photo.photo_link" class="picsize">
                </v-avatar>
                <v-avatar v-else class="picsize-hover" tile>
                  No Picture
                </v-avatar>
              </a>
              <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                @completed="handleCompleted"
                @error="handlerError"
                :cropper-options="cropperOptions"
                :labels="textButton"
                :upload-url="uploadurl"
                trigger="#pick-avatar"
              />
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-form ref="shopForm" lazy-validation>
            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"><span class="color-red">*</span> ร้านค้า : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-if="productMaster.id != 0"
                  disabled
                  v-model="shop"
                  :items="shopList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
                <v-select
                  v-else
                  v-model="shop"
                  :items="shopList"
                  item-text="name"
                  item-value="id"
                  :rules="[ v => v.id!=0 || 'กรุณาระบุ', ]"
                  ref="shop"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>
          </v-form>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"><span class="color-red">*</span> ชื่อสินค้า : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.name"
                @change="value => productMaster.name = Trim_value(value)"
                :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                ref="productMastername"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> รหัสสินค้า : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.productCode"
                @change="value => productMaster.productCode = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> รหัส CF : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.cfCode"
                @change="value => productMaster.cfCode = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
                maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> บริษัท : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.company"
                @change="value => productMaster.company = Trim_value(value)"
                ref="productMasterproducompany"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> แบรนด์ : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.brand"
                @change="value => productMaster.brand = Trim_value(value)"
                ref="productMasterprodubrand"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> ราคาขาย : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.unitPrice"
                @change="value => productMasterItem.unitPrice = Trim_value(value)"
                ref="productMasterItemunitPrice"
                @keypress="isNumberWithDot($event)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> SKU : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.sku"
                @change="value => productMasterItem.sku = Trim_value(value)"
                ref="productMasterItemsku"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> บาร์โค้ด : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.barcode"
                @change="value => productMasterItem.barcode = Trim_value(value)"
                ref="productMasterItembarcode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> กว้าง : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic1"
                @change="value => productMasterItem.cubic1 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> ยาว : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic2"
                @change="value => productMasterItem.cubic2 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> สูง : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic3"
                @change="value => productMasterItem.cubic3 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> น้ำหนัก : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.weight"
                @change="value => productMasterItem.weight = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"><span class="color-red">*</span> วันที่สิ้นสุด : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-radio-group row v-model="productMaster.selectEndDate">
                <v-radio v-for="i in productMaster.checkEndDate" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
              </v-radio-group>
              <v-menu
                v-if="productMaster.selectEndDate"
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    outlined
                    hide-details
                    readonly
                    label=""
                    prepend-inner-icon="mdi-calendar"
                    @blur="endDate = parseDate(dateEnd)"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                    clearable
                    @click:clear="dateEnd = null"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="menuEndDate = false" @change="dateEnd=formatDate(endDate)" no-title :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            <v-col cols="12" md="2" class="py-0"/>
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> หมายเหตุ : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-textarea
                :value="productMaster.remark"
                @change="value => productMaster.remark = Trim_value(value)"
                ref="productMasterremark"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

        </v-card-text>
      </v-card>

      <!-- ข้อมูลสินค้า Product -->
      <v-card v-if="!productMaster.isProductSetTemp" elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-file-document</v-icon><span class="ml-3">ข้อมูลสินค้า</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto">

          <div v-if="!is_prop">

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ภาพสินค้า : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <a id="pick-avatar">
                  <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                    <img :src="photo.photo_link" class="picsize">
                  </v-avatar>
                  <v-avatar v-else class="picsize-hover" tile>
                    No Picture
                  </v-avatar>
                </a>
                <avatar-cropper
                  @uploading="handleUploading"
                  @uploaded="handleUploaded"
                  @completed="handleCompleted"
                  @error="handlerError"
                  :cropper-options="cropperOptions"
                  :labels="textButton"
                  :upload-url="uploadurl"
                  trigger="#pick-avatar"
                />
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ราคาขาย : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.unitPrice"
                  @change="value => productMasterItem.unitPrice = Trim_value(value)"
                  ref="productMasterItemunitPrice"
                  @keypress="isNumberWithDot($event)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> SKU : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.sku"
                  @change="value => productMasterItem.sku = Trim_value(value)"
                  ref="productMasterItemsku"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> บาร์โค้ด : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.barcode"
                  @change="value => productMasterItem.barcode = Trim_value(value)"
                  ref="productMasterItembarcode"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> กว้าง : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic1"
                  @change="value => productMasterItem.cubic1 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ยาว : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic2"
                  @change="value => productMasterItem.cubic2 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> สูง : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic3"
                  @change="value => productMasterItem.cubic3 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> น้ำหนัก : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.weight"
                  @change="value => productMasterItem.weight = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> จำนวนแจ้งเตือน : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.alertQuantity"
                  @change="value => productMasterItem.alertQuantity = Trim_value(value)"
                  ref="productMasterItemalertQuantity"
                  @keypress="isNumberWNoDot($event)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ตัวเลือกสินค้า : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-btn block text class="sshoveroption" @click="OpenOption(true)"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เปิดใช้งานตัวเลือกสินค้า </span></v-btn>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

          </div>
          <div v-else>

            <v-row class="py-2" v-for="(i , indexi) in option" :key="indexi">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ตัวเลือก {{ i.prop }} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="pa-4 pt-0 bgc-fa">

                <v-row>
                  <v-col cols="12" class="text-right">
                    <a @click="CloseOption(i.prop)"><v-icon class="close-option" size="15">fa-times</v-icon></a>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3">
                    <h3 class="color-b3"> ชื่อ : </h3>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-row>
                      <v-col cols="11" class="pt-1 pb-2">
                        <v-text-field
                          :value="i['name']"
                          @change="value => i['name'] = Trim_value(value)"
                          @input="ChangeProp(i.prop,$event)"
                          :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                          outlined
                          dense hide-details
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1"/>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3">
                    <h3 class="color-b3"> ตัวเลือก : </h3>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-row v-for="(ii , indexii) in i.data" :key="indexii" class="ma-0">
                      <v-col cols="11" class="pt-1 pb-2 px-0">
                        <v-text-field
                          :value="ii['name']"
                          @change="value => ii['name'] = Trim_value(value)"
                          @input="ChangeOptionInProp(i.prop,indexii,$event)"
                          :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                          outlined
                          dense hide-details
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" v-if="!productMaster.id && ii['is_base']" class="pr-0">
                        <span v-if="i.data.length>1"><v-icon class="color-b3 pt-4" size="15">fa-trash-alt</v-icon></span>
                      </v-col>
                      <v-col cols="1" v-else class="pr-0">
                        <a v-if="i.data.length>1" @click="Del_Option2(i.prop,indexii)"><v-icon class="color-b3 pt-4 hover-close" size="15">fa-trash-alt</v-icon></a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3"/>
                  <v-col cols="12" md="9">
                    <v-row>
                      <v-col cols="11" class="pt-1 pb-2">
                        <v-btn block text class="sshoveroption" @click="Add_Option2(i.prop)"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เพิ่มตัวเลือก </span></v-btn>
                      </v-col>
                      <v-col cols="1"/>
                    </v-row>
                  </v-col>
                </v-row>

                <div v-if="i.prop==1">
                  <v-row align="start" justify="start" class="pa-1">
                    <div v-for="(ip , indexip) in i.data" :key="indexip">
                      <a :id="'pick-avatar'+indexip" @click="UpdatePhoto(indexip)">
                        <v-avatar v-if="ip.photo_link!=null && ip.photo_link!=''" class="picsize-hover" tile>
                          <img :src="ip.photo_link" class="picsize">
                        </v-avatar>
                        <v-avatar v-else class="picsize-hover" tile>
                          <span v-if="ip.name!=null && ip.name!=''" class="text-cut"> {{ ip.name }} </span>
                          <span v-else> No Picture </span>
                        </v-avatar>
                      </a>
                      <avatar-cropper
                        @uploading="handleUploading"
                        @uploaded="handleUploaded"
                        @completed="handleCompleted"
                        @error="handlerError"
                        :cropper-options="cropperOptions"
                        :labels="textButton"
                        :upload-url="uploadurl"
                        :trigger="'#pick-avatar'+indexip"
                      />
                    </div>
                  </v-row>
                </div>

              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2" v-if="option.length==1">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ตัวเลือก 2 : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-btn block text class="sshoveroption" @click="AddOption()"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เพิ่ม </span></v-btn>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> จำนวนแจ้งเตือน : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.alertQuantity"
                  @change="value => productMasterItem.alertQuantity = Trim_value(value)"
                  @keypress="isNumberWNoDot($event)"
                  ref="productMasterItemalertQuantity"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0"/>
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> รายการตัวเลือกสินค้า : </h3>
              </v-col>
              <v-col cols="12" md="8" class="py-0">

                <!-- PC hidden-xs-only -->
                <v-data-table
                  :headers="headers_option"
                  :items="dataset_option"
                  :items-per-page="9999999999"
                  hide-default-footer
                  class="packhai-border-table-add hidden-xs-only"
                  >
                  <template v-slot:item.unitPrice="{ item }">
                    <v-text-field
                      :value="item.unitPrice"
                      @change="value => item.unitPrice = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1 center-input"
                    />
                  </template>
                  <template v-slot:item.sku="{ item }">
                    <v-text-field
                      :value="item.sku"
                      @change="value => item.sku = Trim_value(value)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1 center-input"
                    />
                  </template>
                  <template v-slot:item.barcode="{ item }">
                    <v-text-field
                      :value="item.barcode"
                      @change="value => item.barcode = Trim_value(value)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1 center-input"
                    />
                  </template>
                  <template v-slot:item.cubic1="{ item }">
                    <v-text-field
                      :value="item.cubic1"
                      @change="value => item.cubic1 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.cubic2="{ item }">
                    <v-text-field
                      :value="item.cubic2"
                      @change="value => item.cubic2 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.cubic3="{ item }">
                    <v-text-field
                      :value="item.cubic3"
                      @change="value => item.cubic3 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.weight="{ item }">
                    <v-text-field
                      :value="item.weight"
                      @change="value => item.weight = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                </v-data-table>

                <!-- Mobile hidden-sm-and-up -->
                <v-data-iterator
                  :items="dataset_option"
                  :items-per-page="9999999999"
                  hide-default-header
                  hide-default-footer
                  class="hidden-sm-and-up"
                >
                  <template v-slot:default="props">
                    <v-row class="ma-0">
                      <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                        <v-card>
                          <v-card-text>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                {{ headers_option[0]["text"] }}
                              </v-col>
                              <v-col cols="8">
                                : {{ item.prop1Description }}
                              </v-col>
                            </v-row>

                            <v-row class="ma-0" v-if="option.length==2">
                              <v-col cols="4">
                                {{ headers_option[1]["text"] }}
                              </v-col>
                              <v-col cols="8">
                                : {{ item.prop2Description }}
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                ราคาขาย
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.unitPrice"
                                    @change="value => item.unitPrice = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                SKU
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.sku"
                                    @change="value => item.sku = Trim_value(value)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                บาร์โค้ด
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.barcode"
                                    @change="value => item.barcode = Trim_value(value)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="ma-0">
                              <v-col cols="4">
                                กว้าง
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic1"
                                    @change="value => item.cubic1 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                ยาว
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic2"
                                    @change="value => item.cubic2 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                สูง
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic3"
                                    @change="value => item.cubic3 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="ma-0">
                              <v-col cols="4">
                                น้ำหนัก
                              </v-col>
                              <v-col cols="8" class="packhai-showtime">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.weight"
                                    @change="value => item.weight = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>

              </v-col>
            </v-row>

          </div>

        </v-card-text>
      </v-card>

      <!-- ข้อมูลสินค้า Product Set -->
      <v-card v-else elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">ข้อมูลสินค้า</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="px-2" @click="OpenPopup()"><v-icon>mdi-plus</v-icon>เพิ่มรายการ</v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto pa-0">

          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="headers"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
            <template v-slot:item.del="{ item }">
              <a @click="DeleteProduct(item.name,dataset.indexOf(item))"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
            </template>
            <template v-slot:item.no="{ item }">
              {{ formatMoney(dataset.length - dataset.indexOf(item)) }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              {{item.name}}
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-text-field
                :value="item.quantity"
                :rules="[ v => !!v && v!=0 || 'กรุณาระบุ', ]"
                @change="value => item.quantity = Trim_value(value)"
                @keypress="isNumberWNoDot($event)"
                dense hide-details
                autocomplete="off"
                class="center-input"
              />
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
          <v-data-iterator
            v-if="size=='xs' || size=='sm'"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0" >

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ชื่อ
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.name != null">: {{ item.name }}</span>
                                    </div>
                                  </template>
                                  <span v-if=" item.name != null">{{ item.name }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  ลักษณะ
                                </div>
                                <div class="cut-text">
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  รหัสสินค้า
                                </div>
                                <div class="cut-text">
                                  : {{ item.productCode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  SKU
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  Barcode
                                </div>
                                <div class="cut-text">
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  จำนวน
                                </div>
                                <div>
                                  :
                                  <v-text-field
                                    :value="item.quantity"
                                    :rules="[ v => !!v && v!=0 || 'กรุณาระบุ', ]"
                                    @change="value => item.quantity = Trim_value(value)"
                                    @keypress="isNumberWNoDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="center-input"
                                  />
                                </div>
                                <v-spacer/>
                                <a @click="DeleteProduct(item.name,dataset.indexOf(item))"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>


                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
      </v-card>

    </v-form>

    <!-- sumbit -->
    <v-card elevation="1">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-btn :elevation="1" color="primary" class="pa-2" @click="UpdateStock(productMaster.isProductSetTemp)">
              <v-icon size="14" class="mr-1">fa-solid fa-floppy-disk</v-icon>
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import axios from "axios"
  import Swal from "sweetalert2" 
  import Loading from "@/website/components/Loading"
  import AvatarCropper from "vue-avatar-cropper"
  import { globalService, stockService_dotnet, shopService_dotnet } from "@/website/global"
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { isNumberWithDot, isNumberWNoDot, formatMoney, DateNowNotNull, Trim_value, Trim_rules, formatDatetime, formatDate_thai, formatDate_eng } from "@/website/global_function"
  export default {
    components: {
      Loading,
      AvatarCropper
    },
    data () {
      return {
        // page
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
        page_loading: true,
        loading: false,
        navigete: null,

        // แสดงภาพ
        ShowPhoto: false,
        ShowPhotoLink: '',

        shopList: [{id:0, name: "--เลือกร้านค้า--"}],
        shop: {id:0, name: "--เลือกร้านค้า--"},

        productMaster: {
          "id": 0,
          "shopId": 0,
          "name": null,
          "productCode": null,
          "cfCode": null,
          "brand": null,
          "company": null,
          "prop1": null,
          "prop2": null,
          "remark": null,
          "isProductSet": 0,
          "isProductSetTemp": 0,
          "isProductSetList": [
            { "value": 0 , "label": "ไม่ใช่สินค้าจัดเช็ต" },
            { "value": 1 , "label": "เป็นสินค้าจัดเช็ต" },
          ],
          "promotionEndDate": null,
          "selectEndDate": 0,
          "checkEndDate": [
            { "value": 0 , "label": "ไม่มีสิ้นสุด" },
            { "value": 1 , "label": "สิ้นสุดวันที่" },
          ],

        },

        // วันที่สิ้นสุด promotionEndDate
        endDate: null,
        dateEnd: null,
        menuEndDate: false,

        productMasterItem: {
          "id": 0,
          "productMasterId": 0,
          "photoLink": null,
          "isBox": false,
          "unitPrice": null,
          "barcode": null,
          "sku": null,
          "cubic1": null,
          "cubic2": null,
          "cubic3": null,
          "weight": null,
          "alertQuantity": null,
        },

        // option
        is_prop: false,
        headers_option: [],
        dataset_option: [],
        option: [],

        // Product Set
        headers: [
          { text: 'ลบ',align: 'center',sortable: false,value: 'del', width: '60px'},
          { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
          { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '70px'},
          { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', width: '350px'},
          { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', width: '250px'},
          { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '110px'},
          { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
          { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
          { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '90px'},
        ],
        dataset: [],

        // popup
        headers_selectProductList: [
          { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
          { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
          { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '60px'},
          { text: 'ชื่อ',align: 'left',sortable: false,value: 'name'},
          { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1'},
          { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode'},
          { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
          { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
        ],
        popupsearch: null,
        searchStockModelPopup: null,
        addStockPopup: {
          "MaxWidth": "50%",
          "MaxHeight": 90,
          "dialog": false,
          "header": [
            { text: 'ชื่อ',align: 'center',sortable: false,value: 'Name',},
          ],
          "dataset": [],
          "selectProductList": [],
          "productMasterItemIdList": [],
          "loading": false,
        },

        // AvatarCropper
        cropperOptions:{
          background:false,
          viewMode:1,
          aspectRatio: 1,
          autoCropArea: 1,
          movable: false,
          zoomable: false
        },
        textButton: {
          submit:"ตกลง"
          ,cancel:"ยกเลิก"
        },
        uploadurl: globalService+"uploadPicture",
        message: "ready",
        photo: {
          "is_base": false,
          "photo_link": null,
          "photo_uploand": {
            "file":[],
            "fileSize":0,
            "from_Picture":{},
            "from_path":''
          },
        },
        countStockshop: 0,

        // paginations
        checkPage: true,
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 15,
        offsets: 0,

        part_return: null,

        // ขนาดจอ
        size: 'lg',
        window: {
          width: 0,
          height: 0
        },
      }
    },
    watch:{
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    async created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
      if (this.window.width<960){this.addStockPopup.MaxWidth='100%'}
      else{this.addStockPopup.MaxWidth='70%'}
      if (this.size=='xs' || this.size=='sm'){
        this.limits = 25
        this.addStockPopup.MaxHeight = (90*this.window.height)/100
      }else{
        this.addStockPopup.MaxHeight = (this.addStockPopup.MaxHeight*this.window.height)/100
        // cal limits
        this.limits = parseInt((((((this.addStockPopup.MaxHeight-57)-52)-56)-48)-10)/48)
      }

      var data = JSON.parse(localStorage.getItem("data"))
      this.productMaster.isProductSetTemp = data.isProductSet
      this.part_return = data.part_return
      if (data!=null && data.productMasterId!=null && data.productMasterItemId!=null){
        this.productMaster.id = data.productMasterId
        this.productMasterItem.id = data.productMasterItemId
      } else {
        this.productMaster.id = 0
        this.productMasterItem.id = 0
      }

      this.navigete = "เพิ่มสินค้า"
      if (this.productMaster.id!=0 && this.productMasterItem.id!=0){
        this.navigete = "แก้ไขสินค้า"
        await this.Load_Data()
      } else {
        let response = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "shopType": 1,
          "branchId": localStorage.getItem('Branch_BranchID'),
        }, {headers: this.header_token})
        if (response.status==200) {
          var shopList = response.data.result
          if (shopList.length>0){
            for (var s in shopList){
              this.shopList.push({
                id: shopList[s].shop.id,
                name: shopList[s].shop.name,
              })
            }
          }
        }
      }
      this.page_loading = false
    },
    methods: {
      // loaddata
      async Load_Data() {
        await axios.all([
          axios.post(shopService_dotnet+'Shop/get-shop-list', {
            "shopType": 1,
            "branchId": localStorage.getItem('Branch_BranchID'),
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-myproduct', {
            'productMasterId': this.productMaster.id,
            'productMasterItemId': this.productMasterItem.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productMasterItem-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-vstockmaster-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/count-stockshopitem', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((shopList, myproduct, product, productsetVStockMaster, productset, countStockshop) => {
          shopList = shopList.data.result
          myproduct = myproduct.data
          product = product.data
          productsetVStockMaster = productsetVStockMaster.data
          productset = productset.data
          this.countStockshop = countStockshop.data

          // เพิ่ม Shop
          if (shopList.length>0){
            for (var s in shopList){
              this.shopList.push({
                id: shopList[s].shop.id,
                name: shopList[s].shop.name,
              })
              if (myproduct.shopId==shopList[s].shop.id){
                this.shop = {
                  id: shopList[s].shop.id,
                  name: shopList[s].shop.name,
                }
              }
            }
          }

          // set productMaster
          this.productMaster.name = myproduct.name
          this.productMaster.productCode =  myproduct.productCode
          this.productMaster.cfCode =  myproduct.cfCode
          this.productMaster.brand =  myproduct.brand
          this.productMaster.company =  myproduct.company
          this.productMaster.prop1 =  myproduct.prop1
          this.productMaster.prop2 =  myproduct.prop2
          this.productMaster.remark =  myproduct.remark
          if (myproduct.promotionEndDate!=null){
            this.dateEnd = this.formatDate_thai(myproduct.promotionEndDate)
            this.endDate = this.formatDate_eng(myproduct.promotionEndDate)
            this.productMaster.selectEndDate = 1
          }

          this.productMasterItem.alertQuantity = myproduct.alertQuantity==0 ? null : myproduct.alertQuantity
          if (product.length==1 && (myproduct.prop1==null || myproduct.prop1=="") ){
            // set productMasterItem
            this.productMasterItem.productMasterId = myproduct.productMasterId
            this.productMasterItem.unitPrice = myproduct.unitPrice==null ? null : parseFloat(myproduct.unitPrice).toFixed(2)
            this.productMasterItem.barcode =  myproduct.barcode
            this.productMasterItem.sku =  myproduct.sku
            this.productMasterItem.photoLink =  null
            this.productMasterItem.cubic1 = myproduct.cubic1
            this.productMasterItem.cubic2 = myproduct.cubic2
            this.productMasterItem.cubic3 = myproduct.cubic3
            this.productMasterItem.weight = myproduct.weight
            if (myproduct.photoLink!=null){
              this.productMasterItem.photoLink = myproduct.photoLink
              this.photo.photo_link = myproduct.photoLink
              this.photo.is_base = true
              this.photo.photo_uploand.file = []
              this.photo.photo_uploand.fileSize = 0
              this.photo.photo_uploand.from_Picture = {}
              this.photo.photo_uploand.from_path = ""
            }
          }else{

            // set product option
            this.is_prop = true
            this.option = []
            if (myproduct.prop1!=null){
              this.PushEditOption(1,myproduct.prop1)
            }
            if (myproduct.prop2!=null){
              this.PushEditOption(2,myproduct.prop2)
            }

            // หา prop1 prop2Description
            var prop1 = []
            var prop2 = []
            for (var i in product){
              // หา prop1 photo_link
              var no1 = 0
              for (var p1 in prop1){
                if (prop1[p1]["name"]==product[i]["prop1Description"]){
                  no1 += 1
                }
              }
              if (no1==0){
                prop1.push({
                  "name": product[i]["prop1Description"],
                  "photo_link": product[i]["photoLink"]
                })
              }

              // หา prop2 photo_link
              var no2 = 0
              for (var p2 in prop2){
                if (prop2[p2]["name"]==product[i]["prop2Description"]){
                  no2 += 1
                }
              }
              if (no2==0){
                prop2.push({
                  "name": product[i]["prop2Description"],
                  "photo_link": product[i]["photoLink"]
                })
              }
            }

            // ใส่ ตัวเลือก prop1
            for (var i1 in prop1){
              this.option[0]["data"].push({
                "is_base": true,
                "name": prop1[i1]["name"],
                "photo_link_temp": prop1[i1]["photo_link"],
                "photo_link": prop1[i1]["photo_link"],
                "photo_uploand": {
                  "file":[],
                  "fileSize":0,
                  "from_Picture":{},
                  "from_path":''
                }
              })
            }
            // ใส่ ตัวเลือก prop2
            if (this.option.length>1){
              for (var i2 in prop2){
                if (prop2[i2]!=null){
                  this.option[1]["data"].push({
                    "is_base": true,
                    "name": prop2[i2]["name"],
                    "photo_link_temp": prop2[i2]["photo_link"],
                    "photo_link": prop2[i2]["photo_link"],
                    "photo_uploand": {
                      "file":[],
                      "fileSize":0,
                      "from_Picture":{},
                      "from_path":''
                    }
                  })
                }
              }
            }

            // สร้าง headers
            if (this.option.length==1){
              this.headers_prop1()
              this.headers_option[0]["text"] = this.option[0]["name"]
            }else if (this.option.length==2){
              this.headers_prop2()
              this.headers_option[0]["text"] = this.option[0]["name"]
              this.headers_option[1]["text"] = this.option[1]["name"]
            }

            // สร้าง data-table
            for (var i_pd in product){
              for (var i_p1 in prop1){
                if (product[i_pd]["prop1Description"]==prop1[i_p1]["name"]){
                  product[i_pd]["prop"] = 1
                  product[i_pd]["index"] = parseInt(i_p1)
                }
              }
              for (var i_p2 in prop2){
                if (product[i_pd]["prop2Description"]==prop2[i_p2]["name"]){
                  product[i_pd]["prop2"] = prop2[i_p2] != null ? 2 : prop2[i_p2]
                  product[i_pd]["index2"] = prop2[i_p2] != null ? parseInt(i_p2) : prop2[i_p2]
                }
              }
            }
            // order by index prop1
            this.dataset_option = this.OrderByData(product)
          }

          // set product-list
          for (var k in productsetVStockMaster){
            for (var j in productset){
              if (productsetVStockMaster[k]["productMasterId"]==productset[j]["productMasterId"] && productsetVStockMaster[k]["id"]==productset[j]["productMasterItemId"]){
                productsetVStockMaster[k]["quantity"] = parseInt(productset[j]["quantity"])
              }
            }
          }
          this.dataset = productsetVStockMaster
        }));
      },

      // save
      async UpdateStock(isProductSet){
        await this.CheckFocus()
        if (this.$refs.addstockForm.validate() && this.shop.id > 0) {
          var photoLink_del = null

          if (isProductSet){
            // เช็กรายการสินค้า
            if (this.dataset.length==0){
              this.AlertWarning("เพิ่มรายการสินค้า")
              return
            }

            // set productSet
            var productSet = []
            for (var i in this.dataset){
              var data = {}
              data["productMasterId"] = this.dataset[i]["productMasterId"]
              data["productMasterItemId"] = this.dataset[i]["id"]
              data["quantity"] = parseInt(this.dataset[i]["quantity"])
              data["refProductMasterId"] = this.productMaster.id
              data["refProductMasterItemId"] = this.dataset[i]["id"]
              productSet.push(data)
            }

            // เช็คภาพ
            if (this.photo.photo_link != this.productMasterItem.photoLink){
              photoLink_del = this.productMasterItem.photoLink
              this.productMasterItem.photoLink = this.photo.photo_link
            }

          }else{
            // add photo_link เข้าไป
            if (this.is_prop){
              for (var Odata in this.option[0]["data"]) {
                for (var product in this.dataset_option) {
                  if (Odata == this.dataset_option[product]["index"]){
                    this.dataset_option[product]["photo_link"] = this.option[0]["data"][Odata]["photo_link"]
                    this.dataset_option[product]["alertQuantity"] = this.productMasterItem.alertQuantity
                  }
                }
              }
            }else{
              // เช็คภาพ
              if (this.photo.photo_link != this.productMasterItem.photoLink){
                photoLink_del = this.productMasterItem.photoLink
                this.productMasterItem.photoLink = this.photo.photo_link
              }
            }

            var productMasterItem = []
            var alertQuantity = this.productMasterItem.alertQuantity==null || this.productMasterItem.alertQuantity.length=='' ? null : parseInt(this.productMasterItem.alertQuantity)
            // set prop
            if (!this.is_prop){
              this.productMaster.prop1 = null
              this.productMaster.prop2 = null
              this.productMasterItem.alertQuantity = alertQuantity
              this.productMasterItem.unitPrice = this.productMasterItem.unitPrice==null || this.productMasterItem.unitPrice=="" ? null : parseFloat(this.productMasterItem.unitPrice).toFixed(2)
              this.productMasterItem.sku = this.productMasterItem.sku==null || this.productMasterItem.sku=="" ? null : this.productMasterItem.sku
              this.productMasterItem.barcode = this.productMasterItem.barcode==null || this.productMasterItem.barcode=="" ? null : this.productMasterItem.barcode
              productMasterItem.push(this.productMasterItem)
            } else {
              if (this.option.length==1){
                this.productMaster.prop1 = this.option[0]["name"]
                this.productMaster.prop2 = null
              }else if (this.option.length>1){
                this.productMaster.prop1 = this.option[0]["name"]
                this.productMaster.prop2 = this.option[1]["name"]
              }

              // แปลง type
              for (var pi in this.dataset_option){
                var unitPrice = this.dataset_option[pi]["unitPrice"]
                if (unitPrice!=null && unitPrice!=''){ unitPrice = parseFloat(unitPrice).toFixed(2) }
                productMasterItem.push({
                  "id": this.dataset_option[pi]["id"]==null? 0 : this.dataset_option[pi]["id"],
                  "productMasterId": 0,
                  "isBox": false,
                  "prop": this.dataset_option[pi]["prop"],
                  "index": this.dataset_option[pi]["index"],
                  "prop2": this.dataset_option[pi]["prop2"],
                  "index2": this.dataset_option[pi]["index2"],
                  "prop1Description": this.dataset_option[pi]["prop1Description"],
                  "prop2Description": this.dataset_option[pi]["prop2Description"],
                  "alertQuantity": alertQuantity,
                  "unitPrice": unitPrice!=null && unitPrice!="" ? unitPrice : null,
                  "sku": this.dataset_option[pi]["sku"]!=null && this.dataset_option[pi]["sku"]!="" ? this.dataset_option[pi]["sku"] : null,
                  "barcode": this.dataset_option[pi]["barcode"]!=null && this.dataset_option[pi]["barcode"]!="" ? this.dataset_option[pi]["barcode"] : null,
                  "photoLink": this.dataset_option[pi]["photo_link"],
                  "cubic1": this.dataset_option[pi]["cubic1"],
                  "cubic2": this.dataset_option[pi]["cubic2"],
                  "cubic3": this.dataset_option[pi]["cubic3"],
                  "weight": this.dataset_option[pi]["weight"]
                })
              }
            }
          }

          // เช็กวันที่ แปลง
          this.productMaster.promotionEndDate = null
          if (this.productMaster.selectEndDate){
            this.productMaster.promotionEndDate = new Date(this.endDate+" 00:00:00").toISOString()
          }

          // ckech isProductSet
          this.productMaster.isProductSet = this.productMaster.isProductSetTemp==1 ? true : false


          this.loading = true
          this.productMaster.shopId = this.shop.id
          if (isProductSet){
            // update productset
            let response = await axios.post(stockService_dotnet+'Stock/update-productset', {
              "productSet": productSet,
              "productMaster": this.productMaster,
              "productMasterItem": this.productMasterItem,
            }, {headers: this.header_token} )
            if (response.status==200 || response.status==204) {
              if (response.data.status!=200){
                this.loading = false
                this.AlertWarning(response.data.text)
              } else {
                // ลบ ภาพ
                if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
                if (this.is_prop){ await this.DelPhotoOption() }

                this.loading = false
                this.AlertSuccess()
                this.$router.push(this.part_return)
              }
            } else {
              this.loading = false
              this.AlertError()
            }
          }else{

            // update product
            let response = await axios.post(stockService_dotnet+'Stock/update-product', {
              "productMaster": this.productMaster,
              "productMasterItem": productMasterItem,
            }, {headers: this.header_token} )
            if (response.status==200 || response.status==204) {
              if (response.data.status!=200){
                if (response.data.status==205){
                  this.Load_Data()
                  this.loading = false
                  this.AlertWarning(response.data.text)
                } else if (response.data.status==203 || response.data.status==204) {
                  this.loading = false
                  this.AlertWarning(response.data.text)
                }
              } else {
                // ลบ ภาพ
                if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
                await this.DelPhotoOptionTemp()

                this.loading = false
                this.AlertSuccess()
                this.$router.push("/my-product")
              }
            } else {
              this.loading = false
              this.AlertError()
            }
          }
        }
      },
      async CheckFocus(){
        if (this.shop.id == 0){
          this.$vuetify.goTo(this.$refs.shop, { offset: 50 })
        }

        if (this.productMaster.name == "" || this.productMaster.name == null){
          this.$vuetify.goTo(this.$refs.productMastername, { offset: 50 })
          this.$refs.productMastername.focus()
          return
        }
      },
      DeleteProduct(name,index){
        Swal.fire({
          icon: 'warning',
          title: 'ลบ ใช่ หรือ ไม่',
          text: 'ต้องการลบ '+name+' ใช่ หรือ ไม่',
          confirmButtonText: 'Yes!',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.dataset.splice(index,1)
           }
        })
      },

      // optinn
      async OpenOption(is_prop){
        await this.headers_prop1()

        // เพิ่ม prop
        await this.PushOption(1)
        this.dataset_option = await this.StartDataProduct(this.dataset_option)
        if (this.photo.photo_link != '' && this.photo.photo_link != null){ this.productMasterItem.photo_link = this.photo.photo_link }
        if (this.productMasterItem.id > 0){
          this.dataset_option[0].id = this.productMasterItem.id
          this.dataset_option[0].alertQuantity = this.productMasterItem.alertQuantity
          this.dataset_option[0].unitPrice = this.productMasterItem.unitPrice
          this.dataset_option[0].sku = this.productMasterItem.sku
          this.dataset_option[0].barcode = this.productMasterItem.barcode
          this.dataset_option[0].photo_link = this.productMasterItem.photo_link
          this.option[0].data[0].photo_link = this.productMasterItem.photo_link
        }

        // del photo
        await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
        if (this.productMasterItem.id == 0){ this.photo.photo_link = null }
        this.is_prop = is_prop
      },
      StartDataProduct(dataset_option){
        dataset_option = [
          {
            "id": null,
            "prop": 1,
            "index": 0,
            "prop2": null,
            "index2": null,
            "prop1Description": null,
            "prop2Description": null,
            "alertQuantity": null,
            "unitPrice": null,
            "sku": null,
            "barcode": null,
            "photo_link": null,
            "cubic1": null,
            "cubic2": null,
            "cubic3": null,
            "weight": null,
          }
        ]
        return dataset_option
      },
      PushOption(prop){
        this.option.push({
          "prop": prop,
          "name": null,
          "data": [
            {
              "is_base": false,
              "name": null,
              "photo_link_temp": null,
              "photo_link": null,
              "photo_uploand": {
                "file":[],
                "fileSize":0,
                "from_Picture":{},
                "from_path":''
              }
            },
          ]
        })
      },
      PushEditOption(prop,name){
        this.option.push({
          "prop": prop,
          "name": name,
          "data": []
        })
      },
      headers_prop1(){
        this.headers_option = [
          { align: 'center', text: 'ชื่อ', value: 'prop1Description', sortable: false },
          { align: 'center', text: 'ราคาขาย', value: 'unitPrice', sortable: false, width: '110px' },
          { align: 'center', text: 'SKU', value: 'sku', sortable: false, width: '110px' },
          { align: 'center', text: 'บาร์โค้ด', value: 'barcode', sortable: false, width: '110px' }, 
          { align: 'right', text: 'กว้าง', value: 'cubic1', sortable: false, width: '110px' },
          { align: 'right', text: 'ยาว', value: 'cubic2', sortable: false, width: '110px' },
          { align: 'right', text: 'สูง', value: 'cubic3', sortable: false, width: '110px' },
          { align: 'right', text: 'น้ำหนัก', value: 'weight', sortable: false, width: '110px' },
        ]
      },
      headers_prop2(){
        this.headers_option = [
          { align: 'center', text: 'ชื่อ', value: 'prop1Description', sortable: false },
          { align: 'center', text: 'ชื่อ', value: 'prop2Description', sortable: false },
          { align: 'center', text: 'ราคาขาย', value: 'unitPrice', sortable: false, width: '110px' },
          { align: 'center', text: 'SKU', value: 'sku', sortable: false, width: '110px' },
          { align: 'center', text: 'บาร์โค้ด', value: 'barcode', sortable: false, width: '110px' },
          { align: 'right', text: 'กว้าง', value: 'cubic1', sortable: false, width: '110px' },
          { align: 'right', text: 'ยาว', value: 'cubic2', sortable: false, width: '110px' },
          { align: 'right', text: 'สูง', value: 'cubic3', sortable: false, width: '110px' },
          { align: 'right', text: 'น้ำหนัก', value: 'weight', sortable: false, width: '110px' },
        ]
      },
      async CloseOption(prop){
        // del picture
        if (prop==1){
          await this.DelPhotoOption()
        }

        // del option
        if (this.option.length==1){
          this.option = []
        }

        if (this.option.length>1){
          // ลบ prop
          for (var i in this.option){
            if (this.option[i]["prop"] == prop){
              this.option.splice(i,1)
            }
          }
          // อัพเดท prop
          for (var ii=0; ii<this.option.length; ii++){
            this.option[ii]["prop"] = ii+1
            // for (var data in this.option[ii]["data"]){
            //   this.option[ii]["data"][data]["is_base"] = false
            //   this.option[ii]["data"][data]["photo_link"] = null
            // }
          }
          await this.headers_prop1()
          await this.SetNameOptionIndex0()
          await this.CloseCalProduct()
        }

        // กลับไปเป็นแบบ ไม่มี prop
        if (this.option.length==0){
          this.is_prop = false
        }
      },
      async DelPhotoOption(){
        if (this.option.length>0){
          for (var dp1 in this.option[0].data){
            if (this.option[0].data[dp1].photo_link!=this.option[0].data[dp1].photo_link_temp){
              await this.DelPhoto(this.option[0].data[dp1].photo_link,false)
            }
          }
        }
      },
      async DelPhotoOptionTemp(){
        if (this.option.length>0){
          for (var dp1 in this.option[0].data){
            if (this.option[0].data[dp1].photo_link!=this.option[0].data[dp1].photo_link_temp){
              await this.DelPhoto(this.option[0].data[dp1].photo_link_temp,false)
            }
          }
        }
      },
      SetNameOptionIndex0(){
        if (this.option[0]["name"]!='' && this.option[0]["name"]!=null){
          this.headers_option[0]["text"] = this.option[0]["name"]
        }
      },
      async CloseCalProduct(){
        var dataset_option = []
        for (var i in this.option){
          for (var ii in this.option[i]["data"]){
            dataset_option = await this.AddDataProduct(dataset_option, parseInt(this.option[i]["prop"]), parseInt(ii), null, null, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null, null)
          }
        }
        this.dataset_option = dataset_option
      },
      AddDataProduct(dataset_option, Pprop, Pindex, Pprop2, Pindex2, Pprop1Description, Pprop2Description, PalertQuantity, PunitPrice, Psku, Pbarcode, Pcubic1, Pcubic2, Pcubic3, Pweight){
        var id = null
        var prop = Pprop
        var index = Pindex
        var prop2 = Pprop2
        var index2 = Pindex2
        var prop1Description = Pprop1Description
        var prop2Description = Pprop2Description
        var alertQuantity = PalertQuantity
        var unitPrice = PunitPrice
        var sku = Psku
        var barcode = Pbarcode
        var photo_link = null
        var cubic1 = Pcubic1
        var cubic2 = Pcubic2
        var cubic3 = Pcubic3
        var weight = Pweight
        dataset_option.push({
          "id": id,
          "prop": prop,
          "index": index,
          "prop2": prop2,
          "index2": index2,
          "prop1Description": prop1Description,
          "prop2Description": prop2Description,
          "alertQuantity": alertQuantity,
          "unitPrice": unitPrice,
          "sku": sku,
          "barcode": barcode,
          "photo_link": photo_link,
          "cubic1": cubic1,
          "cubic2": cubic2,
          "cubic3": cubic3,
          "weight": weight
        })
        return dataset_option
      },
      ChangeProp(prop,name){
        this.headers_option[prop-1]["text"] = 'ชื่อ'
        if (name!='' && name!=null){
          this.headers_option[prop-1]["text"] = name
        }
      },
      ChangeOptionInProp(prop,index,name){
        var prop_name = null
        var index_name = null
        var prop_description_name = null
        if (prop==1){
          prop_name = "prop"
          index_name = "index"
          prop_description_name = "prop1Description"
        } else if (prop==2){
          prop_name = "prop2"
          index_name = "index2"
          prop_description_name = "prop2Description"
        }

        for (var i in this.dataset_option){
          if (this.dataset_option[i][prop_name]==prop && this.dataset_option[i][index_name]==index){
            this.dataset_option[i][prop_description_name] = name
          }
        }
      },
      async Del_Option2(prop,index){
        if (prop==1 && !this.option[0]["data"][index]["is_base"]){
          await this.DelPhoto(this.option[0]["data"][index]["photo_link"],this.option[0]["data"][index]["is_base"])
        }

        // ลบลิส option
        for (var i in this.option){
          if (this.option[i]["prop"] == prop){
            this.option[i]["data"].splice(index,1)
          }
        }

        var prop_name = null
        var index_name = null
        if (prop==1){
          prop_name = "prop"
          index_name = "index"
        } else if (prop==2){
          prop_name = "prop2"
          index_name = "index2"
        }

        // ลบลิส Data product
        var Dataproduct = []
        for (var ii in this.dataset_option){
          if (this.dataset_option[ii][prop_name] == prop && this.dataset_option[ii][index_name] != index){
            Dataproduct.push(this.dataset_option[ii])
          }
        }

        // update index
        for (var iii in Dataproduct){
          if (parseInt(Dataproduct[iii][index_name])>parseInt(index)){
            Dataproduct[iii][index_name] -=1
          }
        }
        this.dataset_option = Dataproduct
      },
      async Add_Option2(prop){
        var index = null
        for (var i in this.option){
          if (this.option[i]["prop"] == prop){
            this.option[i]["data"].push({
              "is_base": false,
              "name": null,
              "photo_link": null,
              "photo_link_temp": null,
              "photo_uploand": {
                "file":[],
                "fileSize":0,
                "from_Picture":{},
                "from_path":''
              }
            })
            index = this.option[i]["data"].length-1
          }
        }

        await this.CalProduct(prop, index)
      },
      UpdatePhoto(index){
        this.pic_index = index
      },
      async AddOption(){
        await this.PushOption(2)
        await this.headers_prop2()
        await this.SetNameOptionIndex0()

        for (var i in this.dataset_option) {
          this.dataset_option[i]["prop2"] = this.option[this.option.length-1]["prop"]
          this.dataset_option[i]["index2"] = 0
        }
      },
      async CalProduct(prop, index){
        // เพิ่ม option
        if (this.option.length==1){
          this.dataset_option = await this.AddDataProduct(this.dataset_option, prop, index, null, null, null, null, null, null, null, null, null, null, null, null)
        }

        if (this.option.length>1){
          var check_prop = 1
          if (prop==1){check_prop=2}
          var dataset_option = this.dataset_option
          for (var i in this.option) {
            // เอาแค่ ตัวเลือกที่1
            if (this.option[i]["prop"]==check_prop){
              for (var ii in this.option[i]["data"]) {
                if (check_prop==1){
                  dataset_option = await this.AddDataProduct(dataset_option, parseInt(this.option[i]["prop"]), parseInt(ii), prop, index, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null, null)
                }else{
                  dataset_option = await this.AddDataProduct(dataset_option, prop, index, parseInt(this.option[i]["prop"]), parseInt(ii), null, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null)
                }
              }
            }
          }
          this.dataset_option = dataset_option
          // order by index prop1
          this.dataset_option = await this.OrderByData(this.dataset_option)
        }
      },
      OrderByData(dataset_option){
        var no = []
        var temp = []
        // เก็บ index
        for (var i in dataset_option){
          if (!no.includes(dataset_option[i]["index"])){
            no.push(dataset_option[i]["index"])
          }
        }
        no.sort()
        for (var ii in no){
          for (var iii in dataset_option){
            if (no[ii]==dataset_option[iii]["index"]){
              temp.push(dataset_option[iii])
            }
          }
        }
        return temp
      },

      // popup select product
      async OpenPopup(){
        if (this.shop.id==0){
          this.$vuetify.goTo(this.$refs.shop, { offset: 50 })
          this.$refs.shopForm.validate()
          return
        }

        this.searchStockModelPopup = null
        this.addStockPopup.selectProductList = []
        this.addStockPopup.dataset = []
        this.addStockPopup.productMasterItemIdList = []
        this.addStockPopup.loading = false
        this.popupsearch = null

        for (var i in this.dataset){
          if (!this.addStockPopup.productMasterItemIdList.includes(this.dataset[i].id)){
            this.addStockPopup.productMasterItemIdList.push(this.dataset[i].id)
          }
        }
        this.getSelectProduct(this.limits,0,1,this.searchStockModelPopup)
      },
      async getSelectProduct(limits,offsets,page,searchstock){
        this.loading = true
        let response = await axios.post(stockService_dotnet+'Stock/get-stockmaster-list', {
          "shopId": this.shop.id,
          "keyword": searchstock,
          "skipStockMasterIdList": this.addStockPopup.productMasterItemIdList,
          "skip": offsets,
          "take": limits,
        }, {headers: this.header_token} )
        if (response.status==200) {
          this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.addStockPopup.dataset = []
          for (var i in response.data.resultData){
            response.data.resultData[i]["selectProduct"] = false
            this.addStockPopup.dataset.push(response.data.resultData[i])
          }
          this.SetUp_Product()
          this.loading = false
          this.addStockPopup.dialog = true
        } else {
          this.loading = false
          this.AlertError()
        }
      },
      async PopupsearchStock(limits,offsets){
        if (this.searchStockModelPopup!=null){
          this.popupsearch = this.searchStockModelPopup.trim()
          if (this.popupsearch.length>0){
            this.getSelectProduct(limits,offsets,1,this.popupsearch)
          }else{
            this.getSelectProduct(limits,offsets,1,null)
          }
        }
      },
      ProductSelect(item,index){
        this.addStockPopup.dataset[index]["selectProduct"] = !item["selectProduct"]
        if (item["selectProduct"]){ //นำเข้า selectProductList
          this.addStockPopup.selectProductList.splice(0, 0, item)
        }else{ //นำออก selectProductList
          var data = this.addStockPopup.selectProductList
          for (var i in data){
            if (data[i]["id"] == item["id"]){
              this.addStockPopup.selectProductList.splice(i,1)
              break
            }
          }
        }
      },
      ProductSelectCheckbox(item,index){
        item["selectProduct"] = !item["selectProduct"]
        if (item["selectProduct"]){ //นำเข้า selectProductList
          this.addStockPopup.selectProductList.splice(0, 0, item)
        }else{ //นำออก selectProductList
          var data = this.addStockPopup.selectProductList
          for (var i in data){
            if (data[i]["id"] == item["id"]){
              this.addStockPopup.selectProductList.splice(i,1)
              break
            }
          }
        }
      },
      SetUp_Product(){
        var selectProductIdList = []
        for (var i in this.addStockPopup.selectProductList){
          selectProductIdList.push(this.addStockPopup.selectProductList[i].id)
        }
        for (var a in this.addStockPopup.dataset){
          if (selectProductIdList.includes(this.addStockPopup.dataset[a].id)){
            this.addStockPopup.dataset[a].selectProduct = true
          }
        }
      },
      async Submit_addStockPopup(){
        this.addStockPopup.loading = true
        this.addStockPopup.selectProductList = await this.OrderBy(this.addStockPopup.selectProductList)
        for (var i in this.addStockPopup.selectProductList){
          this.addStockPopup.selectProductList[i]["quantity"] = null
          this.dataset.splice(0, 0, this.addStockPopup.selectProductList[i])
        }
        this.addStockPopup.loading = false
        this.addStockPopup.dialog = false
      },

      // AvatarCropper
      async handleUploading(from_data, xhr) {
        var file_type = from_data.get("file").type.split("/")
        from_data.append("name", 'branch'+localStorage.getItem('Branch_BranchID')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1])
        from_data.append('path', 'Branch/')
        if (this.is_prop && !this.productMaster.isProductSetTemp){
          var photo_uploand = this.option[0]["data"][this.pic_index]["photo_uploand"]
          photo_uploand['from_Picture'] = from_data
          photo_uploand['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Branch/'+'branch'+localStorage.getItem('Branch_BranchID')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1]
        }else{
          this.photo.photo_uploand['from_Picture'] = from_data
          this.photo.photo_uploand['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Branch/'+'branch'+localStorage.getItem('Branch_BranchID')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1]
        }
        this.message = "uploading...";
      },
      async handleUploaded(response, from_data, xhr) {
        if (response=="OK") {
          if (this.is_prop && !this.productMaster.isProductSetTemp){
            await this.DelPhoto(this.option[0]["data"][this.pic_index]["photo_link"],this.option[0]["data"][this.pic_index]["is_base"])
            this.option[0]["data"][this.pic_index]["photo_link"] = this.option[0]["data"][this.pic_index]["photo_uploand"]['from_path']
            this.option[0]["data"][this.pic_index]["is_base"] = false
          }else{
            await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
            this.photo.photo_link = this.photo.photo_uploand['from_path']
            this.photo.is_base = false
          }
          this.message = "user avatar updated.";
        }
      },
      handleCompleted(response, from_data, xhr) {
        this.message = "upload completed.";
      },
      handlerError(message, type, xhr) {
        this.message = "Oops! Something went wrong...";
      },
      async DelPhoto(photoLink,is_base){
        if (photoLink!='' && photoLink!=null && !is_base){
          var pathRemove = photoLink
          pathRemove = pathRemove.split("/");
          pathRemove = pathRemove[3]+'/'+pathRemove[4]
          let response = await axios.post(globalService+'removePicture', {'path':pathRemove})
        }
      },

      // size จอ
      handleResize() {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
        if (this.window.width < 600) {
          this.size = 'xs'
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = 'sm'
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = 'md'
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = 'lg'
        } else if (this.window.width >= 1904) {
          this.size = 'xl'
        }
      },

      // วันที่
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      allowedDates: val => val >= new Date().toISOString().substr(0, 10),

      // เรียกใช้
      ChackEditRdo(id){
        if (id==0 || this.countStockshop ==0){
          return false
        }
        return true
      },
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          await this.getSelectProduct(this.limits,offsets,this.page,this.popupsearch)
          this.pageTemp = this.page
        }
      },
      async OrderBy(data){
        var dataset = []
        for (var i=data.length-1; i>=0; i--){
          dataset.push(data[i])
        }
        return dataset
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      async toBack(path){
        // del photo
        await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
        await this.DelPhotoOption()
        this.$router.push('/'+path)
      },
      isNumberWithDot,
      isNumberWNoDot,
      formatMoney,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      formatDatetime,
      formatDate_thai,
      formatDate_eng,
      AlertSuccess,
      AlertWarning,
      AlertError,
    },
  }
</script>

<style scoped>
  >>>.v-input__prepend-inner{
    margin-top: 10px;
  }
  >>>.v-card {
    position: static !important;
  }
  >>>.vue-avatar-cropper-demo {
    max-width: 18em;
    margin: 0 auto;
  }
  >>>.avatar {
    width: 160px;
    border-radius: 6px;
    display: block;
    margin: 20px auto;
  }
</style>
